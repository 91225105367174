/* Imports */
import axios from 'axios';

export default {
  state: {
    filters: [],
    hasFilters: true,
  },
  getters: {
    getFilters: (state) => state.filters,
    getHasFilters: (state) => state.hasFilters,
  },
  mutations: {
    setFilters: (state, value) => {
      state.filters = value.filters;
    },
    setHasFilters: (state, value) => {
      state.hasFilters = value;
    },
  },
  actions: {
    loadHasFilters: async ({ commit }, value) => {
      commit('setHasFilters', value);
    },
    loadFilters: async ({ commit, getters }) => {
      const queryObject = {
        ...getters.getQueryParams,
        categoryKey: getters.getCategoryKey,
        locale: getters.getLocale,
        storeCode: getters.getStoreCode,
        appName: 'www-category-pages',
      };

      const queryString = new URLSearchParams(queryObject).toString();
      const url = `${window?.location?.origin}${getters.getPublicPath}/api/getFilters?${queryString}`;
      const response = await axios.get(url);
      if (response.status !== 200) {
        throw new Error(`Received ${response.status}: ${response.statusText}`);
      }
      commit('setFilters', response.data);
    },
  },
};
