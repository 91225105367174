export const stores = {
  mapping: [
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AD',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'AE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AF',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AG',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'AL',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AN',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'AO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AQ',
    },
    {
      STORE: 'AR',
      COUNTRY_CODE: 'AR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AS',
    },
    {
      STORE: 'AT',
      COUNTRY_CODE: 'AT',
    },
    {
      STORE: 'AU',
      COUNTRY_CODE: 'AU',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AW',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AX',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'AZ',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'BA',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BB',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BD',
    },
    {
      STORE: 'BE',
      COUNTRY_CODE: 'BE',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'BF',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'BG',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'BH',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BI',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'BJ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BO',
    },
    {
      STORE: 'BR',
      COUNTRY_CODE: 'BR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BS',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BT',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BV',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'BW',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'BY',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'BZ',
    },
    {
      STORE: 'CA',
      COUNTRY_CODE: 'CA',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CC',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CD',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CF',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CG',
    },
    {
      STORE: 'CH',
      COUNTRY_CODE: 'CH',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CK',
    },
    {
      STORE: 'CL',
      COUNTRY_CODE: 'CL',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'CM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CS',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CV',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'CX',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'CY',
    },
    {
      STORE: 'CZ',
      COUNTRY_CODE: 'CZ',
    },
    {
      STORE: 'DE',
      COUNTRY_CODE: 'DE',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'DJ',
    },
    {
      STORE: 'DK',
      COUNTRY_CODE: 'DK',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'DO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'DZ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'EC',
    },
    {
      STORE: 'EE',
      COUNTRY_CODE: 'EE',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'EG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'EH',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'ER',
    },
    {
      STORE: 'ES',
      COUNTRY_CODE: 'ES',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'ET',
    },
    {
      STORE: 'FI',
      COUNTRY_CODE: 'FI',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'FJ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'FK',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'FM',
    },
    {
      STORE: 'DK',
      COUNTRY_CODE: 'FO',
    },
    {
      STORE: 'FR',
      COUNTRY_CODE: 'FR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'FX',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'GA',
    },
    {
      STORE: 'GB',
      COUNTRY_CODE: 'GB',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GF',
    },
    {
      STORE: 'GB',
      COUNTRY_CODE: 'GG',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'GH',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GI',
    },
    {
      STORE: 'DK',
      COUNTRY_CODE: 'GL',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'GM',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'GN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GP',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'GQ',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'GR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GS',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GT',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GU',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'GW',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'GY',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'HK',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'HM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'HN',
    },
    {
      STORE: 'HR',
      COUNTRY_CODE: 'HR',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'HU',
    },
    {
      STORE: 'ES',
      COUNTRY_CODE: 'IC',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'ID',
    },
    {
      STORE: 'GB',
      COUNTRY_CODE: 'IE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'IL',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'IM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'IN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'IO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'IQ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'IS',
    },
    {
      STORE: 'IT',
      COUNTRY_CODE: 'IT',
    },
    {
      STORE: 'GB',
      COUNTRY_CODE: 'JE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'JM',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'JO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'JP',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'KE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KH',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KI',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KR',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'KV',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'KW',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KY',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'KZ',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'LB',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'LC',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'LI',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'LK',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'LR',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'LS',
    },
    {
      STORE: 'LT',
      COUNTRY_CODE: 'LT',
    },
    {
      STORE: 'BE',
      COUNTRY_CODE: 'LU',
    },
    {
      STORE: 'LV',
      COUNTRY_CODE: 'LV',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'LY',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'MA',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MC',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'MD',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'ME',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'MG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MH',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'MK',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'ML',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MP',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MQ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MS',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'MT',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'MU',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MV',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'MW',
    },
    {
      STORE: 'MX',
      COUNTRY_CODE: 'MX',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'MY',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'MZ',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'NA',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'NC',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'NE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'NF',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'NG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'NI',
    },
    {
      STORE: 'NL',
      COUNTRY_CODE: 'NL',
    },
    {
      STORE: 'NO',
      COUNTRY_CODE: 'NO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'NR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'NU',
    },
    {
      STORE: 'NZ',
      COUNTRY_CODE: 'NZ',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'OM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PA',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PF',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PH',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PK',
    },
    {
      STORE: 'PL',
      COUNTRY_CODE: 'PL',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PS',
    },
    {
      STORE: 'ES',
      COUNTRY_CODE: 'PT',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PW',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'PY',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'QA',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'RE',
    },
    {
      STORE: 'RO',
      COUNTRY_CODE: 'RO',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'RS',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'RU',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'RW',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'SA',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'SB',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'SC',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'SD',
    },
    {
      STORE: 'SE',
      COUNTRY_CODE: 'SE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'SG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'SH',
    },
    {
      STORE: 'SI',
      COUNTRY_CODE: 'SI',
    },
    {
      STORE: 'NO',
      COUNTRY_CODE: 'SJ',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'SK',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'SL',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'SM',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'SN',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'SO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'SR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'ST',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'SV',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'SZ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TD',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TF',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'TG',
    },
    {
      STORE: 'TH',
      COUNTRY_CODE: 'TH',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TJ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TK',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TM',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TN',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TO',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TR',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TT',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TV',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'TW',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'TZ',
    },
    {
      STORE: 'easterneurope',
      COUNTRY_CODE: 'UA',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'UG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'UM',
    },
    {
      STORE: 'US',
      COUNTRY_CODE: 'US',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'UY',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'UZ',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'VA',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'VE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'VG',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'VI',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'VU',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'WF',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'WS',
    },
    {
      STORE: 'mena',
      COUNTRY_CODE: 'YE',
    },
    {
      STORE: 'digital',
      COUNTRY_CODE: 'YT',
    },
    {
      STORE: 'ZA',
      COUNTRY_CODE: 'ZA',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'ZM',
    },
    {
      STORE: 'ssa',
      COUNTRY_CODE: 'ZW',
    },
    {
      STORE: 'VN',
      COUNTRY_CODE: 'VN',
    },
  ],
};
