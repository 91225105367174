/**
 * Returns an array products, filtered by the group attribute
 * @param { Array } products       - The array of products
 * @param {boolean} displayGroup   - Conditional variable for filtering cases: if true, groups are filtered, otherwise, variations are filtered
 * @returns {Array}                - The array of filtered products
 */
export const filterProductByGroup = (products, displayGroup) => {
  if (displayGroup) {
    return products.filter((product) => product.group || (!product.group && !product.groupId));
  }
  return products.filter((product) => !product.group);
};
