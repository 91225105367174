/* Imports */
import { mapGetters } from '../utils/mainUtils';

export const useGenericAnalyticsDataHelper = () => {
  /* Store - Getters */
  const {
    getCustomerGroups: customerGroups,
  } = mapGetters();

  /**
   * Generates analytics data.
   * @returns {object}               - The analytics data object.
   */
  const genericAnalyticsData = () => ({
    employee_flag: customerGroups?.value === 'US' ? 'TRUE' : 'FALSE',
    product_availability: 'NA',
    sale_type: 'NA',
    in_stock: 'NA',
  });

  return { genericAnalyticsData };
};
