export default {
  state: {
    compareActive: false,
    compareItems: [],
    maxCompareItems: 2,
  },
  getters: {
    getCompareItems: (state) => state.compareItems,
    getCompareActiveState: (state) => state.compareActive,
    getMaxCompareItems: (state) => state.maxCompareItems,
  },
  mutations: {
    setComparingActive: (state, value) => {
      state.compareActive = value;
    },
    setMaxCompareItems: (state, value) => {
      state.maxCompareItems = value;
    },
    addItemToCompareList: (state, value) => {
      state.compareItems.push(value);
    },
    removeItemFromCompareList: (state, value) => {
      state.compareItems = state.compareItems.filter((item) => item.id !== value.id);
    },
    emptyCompareList: (state) => {
      state.compareItems = [];
    },
  },
  actions: {
    addItemToCompareList: ({ commit }, payload) => {
      commit('addItemToCompareList', payload);
    },
    setMaxCompareItems: ({ commit }, amount) => {
      commit('setMaxCompareItems', amount);
    },
    toggleCompareItem: ({ commit, getters, dispatch }, item) => {
      const shouldAddItemToCompareList = item.isActive === true
      && getters.getCompareItems.length < getters.getMaxCompareItems
      && !getters.getCompareItems.includes(item);

      if (shouldAddItemToCompareList) {
        commit('addItemToCompareList', item);
      } else {
        commit('removeItemFromCompareList', item);
      }

      // Update query params
      const selectedCompareProductIds = getters.getCompareItems.map((product) => product.id);
      dispatch('setQueryParams', {
        ...getters.getQueryParams,
        compareProduct: selectedCompareProductIds,
        loadData: false,
      });
    },
    setComparingMode: ({ commit, getters, dispatch }, value) => {
      commit('setComparingActive', value);

      if (value === true) {
        dispatch('loadDisplayGroup', false);
        dispatch('fetchPrices');
      } else {
        commit('emptyCompareList');

        const { sorter, currentPage, ...activeFilters } = getters.getQueryParams;

        if (Object.keys(activeFilters).length === 0) {
          dispatch('loadDisplayGroup', true);
        }
      }
    },
  },
};
