/**
 * @typedef {import('vue').default} Vue
 */
import * as Sentry from '@sentry/vue';
import pkg from '../../package.json';

/**
 * Initializes Sentry for error tracking in a Vue.js application.
 * @param {Vue} app - The Vue instance representing the application.
 */
export const sentry = (app) => {
  // Sentry - Only run in production
  if (window?.location?.host === 'www.garmin.com') {
    Sentry.init({
      app,
      dsn: 'https://d8586cf2039cd6efaa0c5194a611bbcc@o430870.ingest.sentry.io/4506258213240832',
      release: `${pkg.name}@${pkg.version}`,
      environment: 'production',
      logErrors: true,
      allowUrls: [/\/c\/assets\//i],
      /* eslint-disable max-len */
      /**
       *
       * @param {object} event     - Sentry processed event object provide to beforeSend callback
       *                           - More details can be found here: https://docs.sentry.io/platforms/javascript/guides/vue/configuration/filtering/#using-platformidentifier-namebefore-send-
       * @returns {object | null} - Returns null if event is intended to be filtered out from logging to Sentry, otherwise it returns the event object
       */
      /* eslint-enable max-len */
      beforeSend(event) {
        const exceptionDetails = event?.exception?.values[0];
        const exceptionValue = exceptionDetails?.value;
        const exceptionStackTrace = exceptionDetails?.stacktrace;

        // exceptions without a value or a stack trace are non-actionable
        if (!exceptionValue || !exceptionStackTrace) {
          return null;
        }

        // Otherwise log error to Sentry
        return event;
      },
    });
  }
};

export default sentry;
