/* eslint-disable function-paren-newline */
/**
 * Helper function to extract the category and subcategory values from the URL
 * @param {string} url - The URL to extract the category and subcategory values from
 * @returns {object}   - The category and subcategory values
 * @example
 * const url = '/en-US/c/automotive/motorcycle-gps-navigation/';
 * const { category, subcategory } = handleUrlForAnalytics(this.$route?.href);
 * the result will be:
 * category = 'Automotive'
 * subcategory = 'Motorcycle-Gps-Navigation'
 */
export const handleUrlForAnalytics = (url) => {
  // Split the URL into values
  const urlValues = url.split('/').filter((value) => value !== ''); // exclude empty string values

  // Helper function to capitalize a value and handle - characters
  const capitalizeValue = (value) => {
    if (typeof value !== 'string') {
      return '';
    }

    // Check if the subcategory contains a question mark
    // Return '' if the subcategory contains a question mark
    if (value.includes('?')) return '';

    const words = value.split('-');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    return capitalizedWords.join('-');
  };

  // Extract and update the category value
  const category = capitalizeValue(urlValues[2]);
  const subcategory = capitalizeValue(urlValues[3]);

  return {
    category,
    subcategory,
  };
};

export default handleUrlForAnalytics;
