/* Imports */
import axios from 'axios';
import VueCookies from 'vue-cookies';

export default {
  state: {
    customerGroups: 'none',
  },
  getters: {
    getCustomerGroups(state) {
      return state.customerGroups;
    },
  },
  mutations: {
    setCustomerGroups(state, payload) {
      state.customerGroups = payload;
    },
  },
  actions: {
    async loadCustomerGroups(context) {
      const { rootGetters } = context;

      const customerId = VueCookies.get('GARMIN-SSO-CUST-GUID');
      const cache = customerId ? 'no-store, max-age=0' : '';
      const url = `${window?.location?.origin}${rootGetters.getPublicPath}/api/getCustomerGroups`;
      const headers = {
        'Cache-Control': cache || 'public, max-age=3600, s-maxage=3600',
      };
      try {
        const response = await axios.post(url, { headers });
        if (response.status !== 200) {
          throw new Error(`Received ${response.status}: ${response.statusText}`);
        }
        return response;
      } catch (error) {
        throw new Error('Error calling getCustomerGroups API');
      }
    },
    async setCustomerGroups({ commit, dispatch }) {
      const customerGroupsCookie = VueCookies.get('GarminBuyCacheKey') ? decodeURIComponent(VueCookies.get('GarminBuyCacheKey')) : null;
      let customerGroups;
      let customerGroupsResponse;
      try {
        customerGroupsResponse = await dispatch('loadCustomerGroups');
      } catch (error) {
        console.error('Error retrieving customer group from server');
      } finally {
        if (customerGroupsResponse?.data && customerGroupsResponse.data.length) {
          customerGroups = customerGroupsResponse.data;
        } else if (customerGroupsCookie) {
          customerGroups = customerGroupsCookie;
        } else {
          customerGroups = 'none';
        }
      }

      const employeeFlag = customerGroups === 'US';
      window.WCS_ANALYTICS_OVERRIDES.employeeFlag = employeeFlag ? 'TRUE' : false;

      commit('setCustomerGroups', customerGroups);
    },
  },
};
