import { createApp } from 'vue';

import get from 'lodash.get';
import {
  utagLinkHandlerDirective,
  utagLinkHandlerDirectiveName,
} from '@garmin/analytics';
import App from './App.vue';
import router from './router/router';
import store from './store';
import { sentry } from './modules/sentry';

// eslint-disable-next-line no-unused-vars
import * as registerComponents from './modules/registerComponents';
// eslint-disable-next-line no-unused-vars
import * as registerIcons from './modules/registerIcons';

const app = createApp(App);
app.use(store);
app.use(router);
app.directive(utagLinkHandlerDirectiveName, utagLinkHandlerDirective);

// Initialize sentry-client
sentry(app);

app.config.globalProperties.$safeGet = get;
app.config.compilerOptions.whitespace = 'preserve';

app.mount('#app');
