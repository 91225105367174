import { stores } from './storeLocaleMapping';

/**
 * Returns the store code for a given locale
 * @param {string} locale - The locale to get the store code for
 * @returns {string}      - The store code for the given locale
 * @example
 * getStoreCode('en-US'); // 'US'
 */
export const getStoreCode = (locale) => {
  let storeCode = 'US';
  stores.mapping.forEach((store) => {
    if (locale === store.COUNTRY_CODE) {
      storeCode = store.STORE;
    }
  });
  return storeCode;
};

export const getCountryAndStoreCode = (locale) => {
  const countryCode = locale?.split('-', 2)?.[1];
  const storeCode = countryCode && getStoreCode(countryCode);
  return { countryCode, storeCode };
};
