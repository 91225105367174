import { createRouter, createWebHistory } from 'vue-router';
import App from '../App.vue';

const routes = [{
  path: '/',
  component: App,
  name: 'App',
}];

const router = createRouter({
  history: createWebHistory(window.location.pathname),
  routes,
  // this function is a built in navigation guard
  // it is scrolling the page based on the URL hash
  // eslint-disable-next-line consistent-return
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        // this is necessary so that the components load before scrolling.
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'auto',
            block: 'nearest',
          });
        }, 500);
      });
    } if (savedPosition) {
      return savedPosition;
    }
  },
});

export default router;
