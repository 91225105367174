<script setup>
/* Imports */
import {
  computed,
  ref,
  onBeforeMount,
  onMounted,
} from 'vue';
import VueCookies from 'vue-cookies';
import { useRouter } from 'vue-router';

/* Analytics */
import {
  sendAnalyticsEvent,
  sendAnalyticsPageView,
  constructAnalyticsCatSubcat,
  handleUrlForAnalytics,
} from '../utils/ga/analytics';

/* Helpers */
import {
  getComponentFromList,
  isProductContainerMissing,
} from '../utils/mainUtils';

/* Composables */
import { useGetComponents } from '../composables/useGetComponents';
import { useGenericAnalyticsDataHelper } from '../composables/useAnalyticsData';

/* Routing */
const router = useRouter();

/* Props */
const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  locale: {
    type: String,
    default: '',
  },
  buyServiceBase: {
    type: String,
    default: '',
  },
  publicPath: {
    type: String,
    default: '',
  },
});

/* Initialize Composables */
const { getComponents } = useGetComponents();
const { genericAnalyticsData } = useGenericAnalyticsDataHelper();

/* State */
const state = ref({
  componentList: getComponents(props.content),
});

/* Lifecycle Hooks -> onBeforeMount */
onBeforeMount(() => {
  const pcQueryParam = new URL(window.location.href).searchParams.get('pc');

  // Another way buy uses promotions passed in by query param ?pc=<promo>
  if (pcQueryParam) VueCookies.set('GarminBuyPC', pcQueryParam, '0', '/', '.garmin.com', null, null, null);
});

/* Lifecycle Hooks -> onMounted */
onMounted(() => {
  window.sendAnalyticsEvent = sendAnalyticsEvent;
});

/* Methods */
const getComponent = (reference) => getComponentFromList(reference, state.value?.componentList);

/* Computed */
const computedIsProductContainerMissing = computed(() => isProductContainerMissing(state.value?.componentList));

/* Analytics Event */
const sendAnalyticsPageViewEvent = () => {
  sendAnalyticsPageView({
    ...genericAnalyticsData(),
    tealium_event: 'page_view',
    item_list_name: constructAnalyticsCatSubcat(...Object.values(handleUrlForAnalytics(router?.currentRoute?.value?.href) || {})) || 'NA',
    item_list_id: props.content?.categoryIdCid || 'NA',
    item_family: 'NA',
    index: 'NA',
    product_id: 'NA',
    product_brand: 'NA',
    product_category: 'NA',
    product_category2: 'NA',
    product_category3: 'NA',
    product_name: 'NA',
    product_original_price: 'NA',
    product_price: 'NA',
    product_url: 'NA',
    is_sale: 'NA',
    price_shown: 'NA',
  });
};

// Send VIL event if the productContainer component is missing - NETFE-15923 for reference
onMounted(() => {
  if (computedIsProductContainerMissing.value) {
    window.addEventListener('utag-ready', () => {
      sendAnalyticsPageViewEvent();
    });
  }
});
</script>

<template>
  <main>
    <component
      :is="{ ...getComponent(reference) }"
      v-for="reference in props.content?.references"
      :key="reference?.id"
      :content="reference"
      :locale="props.locale"
      :buy-service-base="props.buyServiceBase"
      :public-path="props.publicPath"
    />
  </main>
</template>
